import { memo } from 'react';
import { useAbandonedBasket, usePageViewTracker } from './hooks';

/**
 * Dynamic tracking component, which can be used to fire events
 * based on initial or changing data
 */
export const DynamicTracking = memo(() => {
    useAbandonedBasket();
    usePageViewTracker();

    return <></>;
});

import { useSession } from 'next-auth/react';
import { useCallback, useEffect, useRef } from 'react';
import { useMutation } from 'react-query';
import { useBasket } from '~/features/basket/hooks/useBasket';
import { useBasketPaths } from '~/features/basket/hooks/useBasketPaths';
import { qualifyAbandonedBasket } from '~/features/basket/service';
import { useAbandonedBasketState } from './useAbandonedBasketState';

const noop = () => undefined;
export const useAbandonedBasket = () => {
    const { verifyCustomerUrl } = useBasketPaths();
    const { data: session } = useSession();
    const { data: basket } = useBasket();
    const tracked = useRef(false);
    const { qualifiedUrl, setQualifiedUrl } = useAbandonedBasketState();
    const customerNumber = session?.user.customerNumber;
    const basketId = basket?.id;

    const { isLoading, mutateAsync } = useMutation<void, Response, string>(
        (verificationUrl) => qualifyAbandonedBasket(verificationUrl),
        {
            // no-op we don't want to inform the user of errors
            onError: noop,
        }
    );

    const qualifyUserForAbandonedBasket = useCallback(() => {
        if (isLoading) {
            return;
        }

        const verificationUrl = `${verifyCustomerUrl}/${customerNumber}`;

        if (verificationUrl !== qualifiedUrl) {
            setQualifiedUrl(verificationUrl);
            mutateAsync(verificationUrl);
        }
    }, [customerNumber, isLoading, mutateAsync, qualifiedUrl, setQualifiedUrl, verifyCustomerUrl]);

    useEffect(() => {
        if (customerNumber && basketId && !tracked.current) {
            tracked.current = true;
            qualifyUserForAbandonedBasket();
        }
    }, [customerNumber, basketId, qualifyUserForAbandonedBasket]);

    return { qualifyUserForAbandonedBasket };
};
